import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Avatar, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IUserUpdateData } from '../../../models/IUser';
import { PATH_NAME } from '../../../configs/pathName';
import { generalFetcher } from '../../../helpers/Fetchers/fetchers';
import useSWR from 'swr';

const UserProfileDetails = () => {
  const navigate = useNavigate();
  const { data: basicUserInfo } = useSWR(PATH_NAME.API_AUTH_PROFILE, generalFetcher);
  const [profileData, setProfileData] = useState<IUserUpdateData | null>(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState<string | undefined>(undefined);
  const [profilePicture] = useState<File | null>(null);

  const renderConsent = (consent?: boolean) => (consent ? "✔️" : "❌");

  useEffect(() => {
    if (basicUserInfo) {
      setProfileData({
        firstname: basicUserInfo.firstname,
        lastname: basicUserInfo.lastname,
        birth_year: basicUserInfo.birth_year,
        birth_month: basicUserInfo.birth_month,
        email: basicUserInfo.email,
        full_mobile: basicUserInfo.full_mobile,
        email_consent: basicUserInfo.email_consent,
        sms_consent: basicUserInfo.sms_consent
      });
    }
  }, [basicUserInfo]);

  useEffect(() => {
    if (profilePicture) {
      const newProfilePictureUrl = URL.createObjectURL(profilePicture);
      setProfilePictureUrl(newProfilePictureUrl);
      return () => URL.revokeObjectURL(newProfilePictureUrl);
    }
  }, [profilePicture]);

  if (!profileData) return null;

  return (
    <Container sx={{ maxWidth: '400px', padding: 4, backgroundColor: '#ffffff', borderRadius: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 3 }}>
        <Avatar
          src={profilePictureUrl}
          sx={{ width: 100, height: 100, marginBottom: 2 }}
        />
        <Typography variant="h6" gutterBottom>{`${profileData.firstname} ${profileData.lastname}`}</Typography>
      </Box>

      <Divider sx={{ width: '100%', marginBottom: 3 }} />

      <Box sx={{ marginBottom: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}><strong>Email:</strong></Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>{profileData.email}</Typography>
        </Box>
        <Divider sx={{ marginY: 1 }} />
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}><strong>Mobile No:</strong></Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>{profileData.full_mobile}</Typography>
        </Box>
        <Divider sx={{ marginY: 1 }} />
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}><strong>Birth Year:</strong></Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>{profileData.birth_year}</Typography>
        </Box>
        <Divider sx={{ marginY: 1 }} />
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}><strong>Birth Month:</strong></Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>{profileData.birth_month}</Typography>
        </Box>
        <Divider sx={{ marginY: 1 }} />
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}><strong>Email Consent:</strong></Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>{renderConsent(profileData.email_consent)}</Typography>
        </Box>
        <Divider sx={{ marginY: 1 }} />
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ flex: 1, textAlign: 'left', paddingRight: 2 }}><strong>SMS Consent:</strong></Typography>
          <Typography variant="body1" sx={{ flex: 1 }}>{renderConsent(profileData.sms_consent)}</Typography>
        </Box>
        <Divider sx={{ marginY: 1 }} />
      </Box>

      <Button
        variant="contained"
        fullWidth
        onClick={() => navigate(PATH_NAME.EDIT_PROFILE)}
        sx={{ 
          marginTop: 3,
          backgroundColor: 'green', 
          color: 'white', 
          '&:hover': {
            backgroundColor: 'darkgreen',
          }
        }}
      >
        Edit Profile
      </Button>
    </Container>
  );
};

export default UserProfileDetails;