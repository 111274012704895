import { Container, Typography, Box, IconButton, Select, MenuItem, CircularProgress, Alert, FormControl } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { generatePayment, startTransaction } from "../../../api/WalletAPI";
import { handleError } from "../../../helpers/Fetchers/fetchers";
import QRCode from "qrcode.react";
import { GeneratePaymentPayload } from "../../../models/IWallet";
import { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from 'swr';
import { PATH_NAME } from "../../../configs/pathName";
import { generalFetcher } from "../../../helpers/Fetchers/fetchers";

const TopUpPage = () => {
  const [amount, setAmount] = useState<number>(5);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [paynowCode, setPaynowCode] = useState<string | null>(null);
  const [transactionRef, setTransactionRef] = useState<string | null>(null);
  const [transactionId, setTransactionId] = useState<number | null>(null);

  const navigate = useNavigate();
  const { data: paymentStatus } = useSWR(
    transactionId ? PATH_NAME.API_GET_TRANSACTION_PAYMENT(transactionId) : null, generalFetcher, {refreshInterval:3000}
  );
  const { data: storeCreditsResponse } = useSWR(PATH_NAME.API_STORE_CREDITS_BALANCE, generalFetcher);
  const storeCredits = storeCreditsResponse?.balance;

  const qrCodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initializeTransaction = async () => {
      try {
        const transactionResponse = await startTransaction();
        setTransactionRef(transactionResponse.transaction_ref);
        setTransactionId(transactionResponse.id);
      } catch (error) {
        handleError(error, "Failed to start transaction.");
        setError("Failed to start transaction. Please try again.");
      }
    };
    initializeTransaction();
  }, []);

  const handleTopUp = useCallback(async () => {
    if (!transactionRef) return;

    setLoading(true);
    setError(null);
    setPaynowCode(null);

    try {
      const paymentPayload: GeneratePaymentPayload = {
        order_ref: transactionRef,
        amount: amount,
      };

      const paymentResponse = await generatePayment(paymentPayload);
      const paynowCodeResult = paymentResponse.result;

      if (!paynowCodeResult) {
        throw new Error("Failed to generate PayNow QR code.");
      }
      setPaynowCode(paynowCodeResult);
    } catch (error) {
      handleError(error, "Payment failed:");
      setError("Payment failed. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [amount, transactionRef]);

  useEffect(() => {
    if (transactionRef) {
      handleTopUp();
    }
  }, [amount, transactionRef, handleTopUp]);

  useEffect(() => {
    if (paymentStatus?.payment_complete) {
      navigate(PATH_NAME.PAYMENT_SUCCESS, { replace: true });
    }
  }, [paymentStatus, navigate]);

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 0 }}>
        <IconButton onClick={() => navigate(-1)} sx={{ mb: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom textAlign="center">
          Top Up Wallet
        </Typography>
        <Typography variant="h6" gutterBottom textAlign="center">
          Current Balance: SGD {storeCredits !== undefined ? storeCredits : '0'}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
              size="small"  
              sx={{ 
                textAlign: 'center',
                '& .MuiSelect-select': {
                  display: 'flex',
                  justifyContent: 'center',
                }
              }}
            >
              <MenuItem value={5}>$5</MenuItem>
              <MenuItem value={10}>$10</MenuItem>
              <MenuItem value={20}>$20</MenuItem>
              <MenuItem value={50}>$50</MenuItem>
              <MenuItem value={100}>$100</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        {loading ? (
          <CircularProgress size={24} sx={{ mt: 4 }} />
        ) : (
          paynowCode && (
            <Box sx={{ mt: 4, textAlign: "center" }}>
              <div ref={qrCodeRef}>
                <QRCode 
                  value={paynowCode} 
                  size={200} 
                  fgColor="#90137b"
                  bgColor="#ffffff"
                />
              </div>
              <Typography variant="h6" gutterBottom sx={{ fontSize: "14px", mb:4 }}>
                Screenshot this QR code and open your PAYNOW app to scan it to make payment
              </Typography>
            </Box>
          )
        )}
      </Box>
    </Container>
  );
};

export default TopUpPage;
