import axiosInstance from '../api/axiosInstance';
import { PATH_NAME } from '../configs/pathName';
import { handleError } from '../helpers/Fetchers/fetchers';

export const getRewardPoints = async (key: string) => {
    try {
        const response = await axiosInstance.get(key);
        if (response.data && response.data.result && Array.isArray(response.data.result)) {
            return response.data.result;
        } else {
            console.error('Invalid response structure:', response.data);
            throw new Error('Invalid response structure');
        }
    } catch (error) {
        handleError(error, 'fetching reward points');
        throw new Error('Failed to fetch reward points');
    }
};

export const getRewardPointBalance = async () => {
    try {
        const response = await axiosInstance.get(PATH_NAME.API_USER_GET_REWARD_POINTS_BALANCE);
        if (response.data && response.data.result !== undefined) {
            return response.data.result;
        } else {
            console.error('Invalid response structure:', response.data);
            throw new Error('Invalid response structure');
        }
    } catch (error) {
        handleError(error, 'fetching reward point balance');
        throw new Error('Failed to fetch reward point balance');
    }
};