export const profileStyles = {
    container: {
      mt: 2,
      alignItems: { xs: 'flex-start', sm: 'center' },
      textAlign: { xs: 'left', sm: 'center' },
      width: '100%',
      maxWidth: { sm: '500px' },
      mx: { sm: 'auto' },
    },
    title: {
      fontWeight: "bold",
      mb: 0,
    },
    totalBalance: {
      fontWeight: "bold",
    },
    balanceAmount: {
      color: "#3A3A3A",
      mt: 1,
      fontSize:"18px",
      fontWeight:"bold",
    },
    balanceNotAvailable: {
      fontWeight: "bold",
    },
    qrCodeBox: {
      mt: 4,
      display: 'flex',
      justifyContent: 'center',
      position: 'relative'
    },
    qrCodeStyle: {
      borderRadius: "8px",
      border: "1px solid #ddd",
    },
    qrCodeCaption: {
      mt: 2,
      mb:2,
      color: "#888888",
      textAlign: "center",
    },
    qrOverlayBox: (size: number, isMobile: boolean) => ({
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: size,
      height: size,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '3%',
      zIndex: 1,
    }),
    refreshButton: (size: number) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      width: size * 0.9,
      height: size * 0.9,
      padding: 0,
      backgroundColor: `rgba(0, 0, 0, 0.8)`,
      '&:hover': {
        backgroundColor: `rgba(0, 0, 0, 0.7)`,
      },
    }),
    refreshIcon: (isMobile: boolean) => ({
      mb: 1,
      fontSize: isMobile ? 55 : 70,
      color: '#ffffff',
    }),
    topUpButton: {
      mt: 2,
      width: "100%",
      backgroundColor: "#12582D",
      color: "#ffffff",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: "bold",
      borderRadius:"30px",
      py: 1.5,
      '&:hover': {
        backgroundColor: "#539b53",
      },
    },
    linkCardButton: {
      mt: 2,
      width: "100%",
      backgroundColor: "#000000",
      color: "#ffffff",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: "bold",
      borderRadius:"30px",
      py: 1.5,
      '&:hover': {
        backgroundColor: "#000000",
      },
    },
    unlockFridgeButton: {
      mb: 2,
      width: "50%",
      backgroundColor: "#000000",
      color: "#ffffff",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: "bold",
      borderRadius:"30px",
      py: 1.5,
      '&:hover': {
        backgroundColor: "#000000",
      },
    },
    savedCardsContainer: {
      mt: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    savedCardsTitle: {
      fontWeight: 'bold',
      mb: 2,
      textAlign: { xs: 'left', sm: 'center' },
      width: '100%',
    },
    savedCard: {
      mb: 2,
      p: 2,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#e0e0e0',
      borderRadius: '12px',
      boxShadow: 'none',
      width: '100%',
      maxWidth:'500px',
    },
    cardNumber: {
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'left',
    },
    cardExpiry: {
      color: 'text.secondary',
      width: '100%',
      textAlign: 'right',
    },
  };
  