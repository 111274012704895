const assets = {
    images: {
        logo: require('./images/shakesaladlogo.png'),
        banner1: require('./images/Banner1.jpg'),
        banner2: require('./images/Banner2.jpg'),
        mobileBanner1: require('./images/MobileBanner1.jpg'),
        mobileBanner2: require('./images/MobileBanner2.jpg'),
    }
};

export default assets;