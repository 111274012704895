import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import DesktopMenu from '../../common/MenuSideBar/DesktopMenu';
import MobileMenu from '../../common/MenuSideBar/MobileMenu';
import HeaderTopBar from '../../common/Header/HeaderTopBar';
import Footer from '../../common/Footer/Footer';

const MenuPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { slug } = useParams<{ slug?: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!slug) {
      navigate('/');
    } 
  }, [slug, navigate]);

  return (
    <>
      <HeaderTopBar />
      {slug && (isMobile ? <MobileMenu slug={slug} /> : <DesktopMenu slug={slug} />)}
      <Footer />
    </>
  ); 
};

export default MenuPage;