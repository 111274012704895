import { Container, Typography, Box, Button, Card, useMediaQuery, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Alert } from "@mui/material";
import useSWR from 'swr';
import QRCode from "qrcode.react";
import { PATH_NAME } from "../../../configs/pathName";
import { generalFetcher } from "../../../helpers/Fetchers/fetchers";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/features/redux-hooks";
import { logout } from "../../../redux/features/Auth/AuthThunk";
import { LinkCardPayload, IPaymentMethod } from "../../../models/IWallet";
import { linkCard, startTransaction, unlockFridge } from "../../../api/WalletAPI";
import { profileStyles } from '../../../styles/ProtectedPage/profile';
import { clearFridgeCache } from "../../../helpers/FridgeStatus/fridgeStatus";
import RefreshIcon from "@mui/icons-material/Refresh";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const size = isMobile ? 200 : 250;

  const [unlockFridgeButton, setUnlockFridgeButton] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const { data: storeCreditsResponse } = useSWR(PATH_NAME.API_STORE_CREDITS_BALANCE, generalFetcher);
  const { data: basicUserInfo, error: userError } = useSWR(PATH_NAME.API_AUTH_PROFILE, generalFetcher);
  const { data: userProfile } = useSWR(PATH_NAME.API_AUTH_PROFILE, generalFetcher);
  const { data: userQrCode, error: qrError, mutate } = useSWR(PATH_NAME.API_QR_CODE, generalFetcher);
  const { data: paymentMethods } = useSWR(PATH_NAME.API_GET_MY_PAYMENT_METHODS, generalFetcher);

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [qrValue, setQrValue] = useState(userQrCode || "");
  const lastRefreshTimeRef = useRef(Date.now());
  
  const storeCredits = storeCreditsResponse;

  useEffect(() => {
    const timer = setInterval(() => {
      const elapsedTime = Date.now() - lastRefreshTimeRef.current;
      if (elapsedTime >= 60000) {
        setIsOverlayVisible(true);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  
  useEffect(() => {
    if (userQrCode) {
      setQrValue(userQrCode);
    }
  }, [userQrCode]);

  const handleRefreshQrCode = () => {
    setIsOverlayVisible(false);
    mutate();
    lastRefreshTimeRef.current = Date.now();
  };

  const checkUnlockFridgeStatus = () => {
    const session_ref = localStorage.getItem("session_ref");
    const machine_id = localStorage.getItem("machine_id");
    const session_time = localStorage.getItem("session_time");

    if (session_ref && machine_id && session_time) {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - parseInt(session_time);

      if (elapsedTime < 600000) {
        setUnlockFridgeButton(true);
        return true;
      } else {
        clearFridgeCache();
        setUnlockFridgeButton(false);
        return false;
      }
    } else {
      setUnlockFridgeButton(false);
      return false;
    }
  };

  useEffect(() => {
    if (userError) {
      handleLogout();
    }
    checkUnlockFridgeStatus();
  });

  const handleUnlockFridge = async () => {
    const isSessionValid = checkUnlockFridgeStatus();

    if (isSessionValid) {
      try {
        const session_ref = localStorage.getItem("session_ref");
        const machine_id = localStorage.getItem("machine_id");

        if (session_ref && machine_id) {
          const payload = {
            session_ref,
            machine_id: parseInt(machine_id),
          };
          await unlockFridge(payload);
          setModalMessage(
            "The fridge is now open! You can now scan each item's barcode to add to cart. If the fridge is still not open, place the QR code against the scanner to unlock the fridge."
          );
        } else {
          setModalMessage("Missing session details. Please scan the QR code again.");
        }
      } catch (error) {
        console.error("Error unlocking the fridge:", error);
        setModalMessage("An error occurred while unlocking the fridge.");
      }
    } else {
      setModalMessage("The unlock link has expired, please scan the QR code again.");
    }
    setModalOpen(true);
    clearFridgeCache();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout()).unwrap();
      navigate(PATH_NAME.LOGIN);
    } catch (e) {
      console.error("Logout failed", e);
    }
  };

  if (qrError) return <div>QR Code Error</div>;
  if (!basicUserInfo) return <div>Loading...</div>;

  const handleLinkCard = async () => {
    try {
      const transactionResponse = await startTransaction();
      const transactionRef = transactionResponse.transaction_ref;

      const linkCardPayload: LinkCardPayload = {
        name: `${userProfile.firstname} ${userProfile.lastname}`,
        invoiceNo: transactionRef,
        description: "Link Card",
        amount: 0.01,
      };

      const response = await linkCard(linkCardPayload);

      const webPaymentUrl = response.result?.webPaymentUrl;

      if (webPaymentUrl) {
        window.location.href = webPaymentUrl;
      } else {
        console.error("Payment URL not found in the response");
      }
    } catch (error) {
      console.error("Error during Link Card process:", error);
    }
  };

  const handleCardClick = (cardId: number) => {
    navigate(`${PATH_NAME.CARD_DETAILS}${cardId}`);
  };
  
  const balanceBelowThreshold = storeCredits?.balance < 5;
  const noLinkedCard = !Array.isArray(paymentMethods) || paymentMethods.length === 0;

  return (
    <Container>
      <Box sx={profileStyles.container}>
        {(balanceBelowThreshold && noLinkedCard) && (
          <Alert severity="info">Please top up $5 in your wallet or link your credit card to start using.</Alert>
        )}
        <>
          {(!balanceBelowThreshold || !noLinkedCard) && unlockFridgeButton && (
            <Button
              variant="contained"
              sx={profileStyles.unlockFridgeButton}
              onClick={handleUnlockFridge}
            >
              Unlock Fridge
            </Button>
          )}
          <Dialog open={modalOpen} onClose={handleCloseModal}>
            <DialogTitle>Fridge Status</DialogTitle>
            <DialogContent>
              <Typography>{modalMessage}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </>
        <Typography variant={isMobile ? "h5" : "h4"} gutterBottom sx={profileStyles.title}>
          Wallet
        </Typography>
        {storeCredits && storeCredits.balance !== undefined ? (
          <Typography variant={isMobile ? "h4" : "h3"} sx={profileStyles.balanceAmount}>
            {storeCredits.balance < 0 ? `-$ ${Math.abs(storeCredits.balance).toFixed(2)}` : `$ ${storeCredits.balance.toFixed(2)}`}
          </Typography>
        ) : (
          <Typography variant="h6" sx={profileStyles.balanceNotAvailable}>
            $ 0.00
          </Typography>
        )}
        <Typography variant="subtitle1" sx={profileStyles.totalBalance}>
          Total Balance
        </Typography>
        <Box sx={profileStyles.qrCodeBox}>
          <QRCode
            value={qrValue}
            size={size}
            bgColor="#ffffff"
            fgColor="#000000"
            level="H"
            includeMargin={true}
            style={profileStyles.qrCodeStyle}
          />

          {isOverlayVisible && (
            <Box sx={profileStyles.qrOverlayBox(size, isMobile)}>
              <Button onClick={handleRefreshQrCode} variant="contained" sx={profileStyles.refreshButton(size)}>
                <RefreshIcon sx={profileStyles.refreshIcon(isMobile)} />
                <Typography variant="button">CLICK HERE TO REFRESH QR CODE</Typography>
              </Button>
            </Box>
          )}
        </Box>
        <Typography variant="caption" display="block" sx={profileStyles.qrCodeCaption}>
          Place this QR code against the scanner to unlock the fridge
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={profileStyles.topUpButton}
          onClick={() => navigate(PATH_NAME.TOPUP)}
        >
          Top up credits using PayNow
        </Button>

        {!Array.isArray(paymentMethods) || paymentMethods.length === 0 ? (
          <Button
            variant="contained"
            color="primary"
            sx={profileStyles.linkCardButton}
            onClick={handleLinkCard}
          >
            Link credit card
          </Button>
        ) : null}
      </Box>

      <Box sx={profileStyles.savedCardsContainer}>
        <Typography variant="subtitle1" sx={profileStyles.savedCardsTitle}>
          Saved Cards
        </Typography>
        {Array.isArray(paymentMethods) && paymentMethods.length > 0 ? (
          paymentMethods.map((card: IPaymentMethod) => (
            <Card 
              key={card.id} sx={{ 
                ...profileStyles.savedCard, 
                cursor: 'pointer'
              }} 
              onClick={() => handleCardClick(card.id)}
            >
              <Typography variant="body1" sx={profileStyles.cardNumber}>
                xxxx-xxxx-xxxx-{card.card_last_four}
              </Typography>
              <Typography variant="body2" sx={profileStyles.cardExpiry}>
                {card.expiry_date ? `Expires ${card.expiry_date}` : ''}
              </Typography>
            </Card>
          ))
        ) : (
          <Typography variant="body2" sx={profileStyles.savedCard}>
            No saved cards
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default Profile;