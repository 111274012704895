import { Container, Typography, Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from 'swr';
import { PATH_NAME } from '../../../configs/pathName';
import { generalFetcher } from "../../../helpers/Fetchers/fetchers";
import assets from "../../../assets";
import { deletePaymentMethods } from "../../../api/WalletAPI";
import { useState } from "react";

const CardDetailsPage = () => {
  const { cardId } = useParams();
  const [open, setOpen] = useState(false);
  const { data: paymentMethods, error } = useSWR(PATH_NAME.API_GET_MY_PAYMENT_METHODS, generalFetcher);
  const navigate = useNavigate();

  // const { data: basicUserInfo } = useSWR(PATH_NAME.API_AUTH_PROFILE, generalFetcher);

  // const { data: paymentMethods, error } = useSWR(
  //   basicUserInfo ? PATH_NAME.API_GET_MY_PAYMENT_METHODS : null,
  //   generalFetcher
  // );

  if (error) return <div>Error loading card details</div>;
  if (!paymentMethods) return <div>Loading card details...</div>;

  const cardDetails = paymentMethods.find((card: any) => card.id === Number(cardId));

  const handleUnlinkCard = async () => {
    try {
      await deletePaymentMethods(cardId!);
      setOpen(false);
      navigate(PATH_NAME.MEMBER);
    } catch (error) {
      console.error("Error unlinking the card:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' , padding:0}}>
      <Box
        sx={{
          width: '320px',
          height: '180px',
          background: 'linear-gradient(135deg, #12582d, #a3cf69)',
          borderRadius: '15px',
          padding: '20px',
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold', letterSpacing: '1.5px' }}>
          **** **** **** {cardDetails.card_last_four}
        </Typography>
        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'flex-end'}}>
          <Typography variant="body2" sx={{ opacity: 0.8 }}>
            {cardDetails.expiry_date ? cardDetails.expiry_date : 'No Expiry Date'}
          </Typography>
          <img src={assets.images.logo} alt="Logo" style={{ height: '50px' }} />
        </Box>
      </Box>

      <Box sx={{ marginTop: '30px' ,width: '320px'}}>
        <TextField
          fullWidth
          disabled
          label="Card Number"
          value={`**** **** **** ${cardDetails.card_last_four}`}
          sx={{ marginBottom: '15px' }}
        />
        <TextField
          fullWidth
          disabled
          label="Expiry Date"
          value={cardDetails.expiry_date || "No Expiry Date"}
          sx={{ marginBottom: '15px' }}
        />
        <TextField
          fullWidth
          disabled
          label="Card Type"
          value={cardDetails.card_type || "Not Available"}
          sx={{ marginBottom: '15px' }}
        />
      </Box>

      <Button
        variant="contained"
        color="secondary"
        sx={{
          marginTop: '20px',
          width: '320px',
          padding: '12px 0',
          background: 'linear-gradient(135deg, #a3cf69, #12582d)',
          fontWeight: 'bold',
          textTransform: 'none',
          borderRadius: '8px',
        }}
        onClick={handleClickOpen}
      >
        Unlink Card
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{"Unlink Card"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to unlink this card?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUnlinkCard} color="secondary" variant="contained">
            Unlink
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CardDetailsPage;