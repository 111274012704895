export const colors = {
    primaryColor: '#12582D',
    secondaryColor: '#529951',
    backgroundColor: '#FCF9F7',
    textColor: '#333333',
  };
  
  export const fonts = {
    primaryFont: 'Poppins, sans-serif',
  };
  
  export const fontSizes = {
    small: '12px',
    medium: '14px',
    large: '16px',
  };
  
  export const fontWeights = {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600,
  };
  
  export const fontStyles = {
    primaryFont: fonts.primaryFont,
    primaryColor: colors.primaryColor,
    secondaryColor: colors.secondaryColor,
    textColor: colors.textColor,
    fontSizeSmall: fontSizes.small,
    fontSizeMedium: fontSizes.medium,
    fontSizeLarge: fontSizes.large,
    fontWeightLight: fontWeights.light,
    fontWeightRegular: fontWeights.regular,
    fontWeightMedium: fontWeights.medium,
    fontWeightBold: fontWeights.bold,
  };