import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PATH_NAME } from '../../../configs/pathName';
import colorConfigs from '../../../configs/colorConfigs';

const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);
    if (countdown === 0) {
      navigate(PATH_NAME.MEMBER, { replace: true });
    }
    return () => clearInterval(timer);
  }, [countdown, navigate]);

  return (
    <Container 
      style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: 'calc(100vh - 25%)',
        textAlign: 'center' 
      }}
    >
      <Box>
        <CheckCircleIcon style={{ fontSize: 150, color: 'green', marginBottom: '20px' }} />
        <Typography variant="h4" gutterBottom>
          Payment Successful!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Thank you for your payment.
        </Typography>
        <Button 
          variant="contained" 
          sx={{ 
            backgroundColor: colorConfigs.primary,
            color: 'white', 
            '&:hover': { backgroundColor: colorConfigs.primaryActiveBg },
           }}
          onClick={() => navigate(PATH_NAME.MEMBER)}
          style={{ marginTop: '20px' }}
        >
          Back to Home
        </Button>
        <Typography variant="body2" gutterBottom style={{ marginTop: '10px' }}>
          This page will redirect in {countdown} seconds...
        </Typography>
      </Box>
    </Container>
  );
};

export default PaymentSuccessPage;