import { Container, Typography, Box, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import useSWR from 'swr';
import { IOrderItem, IPayment } from "../../../models/IWallet";
import { orderDetailstyle } from "../../../styles/ProtectedPage/ordersDetails";
import { getOrderById } from "../../../api/OrderAPI";

const formatCurrency = (amount: number | undefined) => `$ ${(amount ?? 0).toFixed(2)}`;

const OrderDetailsPage = () => {
  const { orderId } = useParams<{ orderId?: string }>();

  const { data: orderDetails, error: orderError } = useSWR(
    orderId ? ['getOrderById', orderId] : null,
    () => orderId ? getOrderById(orderId) : null
  );

  if (orderError) return <div>Error loading order details</div>;
  if (!orderDetails) return <div>Loading order details...</div>;

  const { 
    subtotal = 0, 
    payment_amount = 0, 
    items = [], 
    payments = []
  } = orderDetails;

  return (
    <Container sx={orderDetailstyle.container}>
      <Typography variant="h6" sx={orderDetailstyle.title}>ORDER DETAILS</Typography>

      <Box sx={orderDetailstyle.summaryBox}>
        <Box sx={orderDetailstyle.headerRow}>
          <Typography sx={orderDetailstyle.headerText}>Product</Typography>
          <Typography sx={orderDetailstyle.headerText}>Total</Typography>
        </Box>

        {items.length > 0 ? (
          items.map((item: IOrderItem) => (
            <Box key={item.sku_id} sx={orderDetailstyle.itemRow}>
              <Box sx={orderDetailstyle.itemDetails}>
                <Typography sx={orderDetailstyle.itemName}>{item.sku_name}</Typography>
                <Typography sx={orderDetailstyle.itemName}>{item.sku_sku}</Typography>
                <Typography sx={orderDetailstyle.itemQuantity}>× {item.quantity}</Typography>
              </Box>
              <Typography sx={orderDetailstyle.itemPrice}>{formatCurrency(item.item_total)}</Typography>
            </Box>
          ))
        ) : (
          <Typography sx={orderDetailstyle.noItemsText}>No items available for this order.</Typography>
        )}

        <Divider sx={orderDetailstyle.divider} />

        <Box sx={orderDetailstyle.subtotalRow}>
          <Typography sx={orderDetailstyle.subtotalText}>Subtotal:</Typography>
          <Typography sx={orderDetailstyle.subtotalText}>{formatCurrency(subtotal)}</Typography>
        </Box>

        <Box sx={orderDetailstyle.paymentRow}>
          <Typography sx={orderDetailstyle.paymentLabel}>Payment method:</Typography>
          {payments.length > 0 ? (
            payments.map((payment: IPayment, index: number) => (
              <Typography key={index} sx={orderDetailstyle.paymentText}>
                {payment.type}
              </Typography>
            ))
          ) : (
            <Typography sx={orderDetailstyle.paymentText}>N/A</Typography>
          )}
        </Box>

        <Divider sx={orderDetailstyle.divider} />

        <Box sx={orderDetailstyle.totalRow}>
          <Typography sx={orderDetailstyle.totalLabel}>TOTAL:</Typography>
          <Typography sx={orderDetailstyle.totalAmount}>{formatCurrency(payment_amount)}</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default OrderDetailsPage;
