const registerPageStyles = {
  container: {
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',
    height: '100vh',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '24px',
    height:'100vh',
},
  paper: {
    p: 0,
    borderRadius: 2,
    backgroundColor: 'background.paper',
  },
  button: {
    mt: 2,
    backgroundColor: '#136936',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#136936',
    },
  },
  phoneInput: {
    width: '100%',
    marginTop: '10px',
  },
  textField: {
    marginTop: '10px',
  },
};

export default registerPageStyles;