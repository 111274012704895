import React, { useState } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Container, Box, Pagination } from '@mui/material';
import { capitalizeFirstLetter } from '../../../configs/fontConfigs';
import { containerStyle, titleStyle, categoryContainerStyle, categoryItemStyle, selectedCategoryTitleStyle, cardStyle, cardMediaStyle, cardContentStyle, cardTitleStyle, categoryIconStyle } from '../../../styles/PublicPage/menu';
import colorConfigs from '../../../configs/colorConfigs';
import Menu from '../../../api/MenuAPI';

const DesktopMenu: React.FC<{ slug: string }> = ({ slug }) => {
  const { data, selectedCategory, setSelectedCategory } = Menu(slug);
  const [page, setPage] = useState<number>(1);
  const itemsPerPage = 8;

  const prependImageUrl = (url: string) => {
    return `https://shake-salad-production.s3.ap-southeast-1.amazonaws.com/${url}`;
  };

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    setPage(1);
  };

  const categoryImages: { [category: string]: string } = {};
  (data || []).forEach(item => {
    if (!categoryImages[item.main_tag_name]) {
      categoryImages[item.main_tag_name] = prependImageUrl(item.featured_image_url);
    }
  });

  const filteredData = data.filter(item => item.main_tag_name === selectedCategory);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  return (
    <Container maxWidth="lg" sx={containerStyle}>
      <Typography variant="h4" sx={titleStyle}>
        Our Menu
      </Typography>
      <Box sx={categoryContainerStyle}>
        <Grid container justifyContent="flex-start" spacing={3}>
          {Array.from(new Set(data.map(item => item.main_tag_name))).map(category => (
            <Grid item key={category} xs={6} sm={3} md={2} sx={{ textAlign: 'center' }}>
              <Box
                onClick={() => handleCategoryClick(category)}
                sx={categoryItemStyle(category === selectedCategory)}
              >
                <img
                  src={categoryImages[category]}
                  alt={category}
                  style={categoryIconStyle}
                />
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  {capitalizeFirstLetter(category)}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {selectedCategory && (
        <>
          <Typography variant="h5" sx={selectedCategoryTitleStyle}>
            {capitalizeFirstLetter(selectedCategory)}
          </Typography>
          <Grid container spacing={2}>
            {paginatedData.map(item => (
              <Grid item xs={12} sm={6} md={3} key={item.id}>
                <Card sx={cardStyle}>
                  <CardMedia
                    component="img"
                    height="300"
                    image={prependImageUrl(item.featured_image_url)}
                    sx={cardMediaStyle}
                  />
                  <CardContent sx={cardContentStyle}>
                    <Typography gutterBottom sx={cardTitleStyle}>
                      {item.name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.primary">
                      $ {item.price.toFixed(2)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => setPage(value)}
              sx={{
                '& .MuiPaginationItem-root': {
                  color: colorConfigs.pagination.color,
                },
                '& .Mui-selected': {
                  backgroundColor: colorConfigs.pagination.activeBg,
                  color: colorConfigs.pagination.activeColor,
                },
              }}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default DesktopMenu;