export const introductionPageStyles = {
    rootPaper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FCF9F7',
    },
    headerBox: {
        position: 'sticky',
        top: 0,
        width: '100%',
        backgroundColor: '#FCF9F7',
        zIndex: 10,
        py: 1,
        textAlign: 'center',
        borderBottom: '1px solid #e0e0e0',
    },
    contentBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 4,
        width: '100%',
        textAlign: 'center',
        backgroundColor: '#FCF9F7',
    },
    buttonNext: {
        color: '#ffffff',
        borderColor: '#12582D',
        '&:hover': {
            borderColor: '#12582D',
            background:'#12582D',
        },
        background:'#12582D',
        borderRadius:'none',
    },
    dotsContainer: {
        display: 'flex',
        justifyContent: 'center',
        mt: 2,
    },
    dot: (isActive: boolean) => ({
        width: isActive ? 18 : 8,
        height: 6,
        borderRadius: '30px',
        backgroundColor: isActive ? '#12582D' : '#e0e0e0',
        margin: '0 3px',
        cursor: 'pointer',
        transition: 'background-color 0.3s, width 0.3s',
    }),
};
