import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import LoginPage from "./components/features/Login/LoginPage";
import RegisterPage from "./components/features/Register/RegisterPage";
import VertifyOTP from "./components/features/Register/VerifyOtpPage";
import HomePage from "./components/features/Home/HomePage";
import DefaultLayout from "./components/layout/DefaultLayout";
import ProtectedLayout from "./components/layout/ProtectedLayout";
import MenuPage from "./components/features/Menu/MenuPage";
import ForgetPasswordPage from "./components/features/Register/ForgetPasswordPage";
import ResetPasswordOTPPage from "./components/features/Register/ResetPasswordOTPPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/menu/:slug?" element={<MenuPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/verify-otp" element={<VertifyOTP />} />
          <Route path="/forget-password" element={<ForgetPasswordPage />} />
          <Route path="/reset-password-verify" element={<ResetPasswordOTPPage/>} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path="/member" element={<MainLayout />}>
            {routes}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;