// Icons
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import QuizIcon from '@mui/icons-material/Quiz';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import StarsIcon from '@mui/icons-material/Stars';

// Pages
import ProfilePage from "../components/features/Profile/ProfilePage";
import TopUpPage from "../components/features/Profile/TopUpPage";
import UserProfileDetails from "../components/features/Profile/UserProfileDetails";
import EditProfile from "../components/features/Profile/EditProfile";
import IntroductionPage from "../components/features/Introduction/IntroductionPage";
import FAQs from "../components/features/FAQs/FAQs";
import CardDetailsPage from "../components/features/Profile/CardDetails";
import OrderDetailsPage from "../components/features/Orders/OrderDetailsPage";
import OrdersPage from '../components/features/Orders/OrdersPage';
import VouchersPage from '../components/features/Vouchers/Vouchers';
import PaymentSuccessPage from '../components/features/Profile/PaymentSuccessPage';

// Model
import { IRouteType } from "../models/IRouteType";
import RewardPointsPage from '../components/features/RewardPoints/RewardPointsPage';


const appRoutes: IRouteType[] = [
    {
        index: true,
        path: '/member',
        element: <ProfilePage />,
        state: "Home",
        sidebarProps: {
            displayText: "Home",
            icon: <DashboardOutlinedIcon />
        },
    },
    {
        path: "vouchers",
        element: <VouchersPage />,
        state: "Vouchers",
        sidebarProps: {
            displayText: "Vouchers",
            icon: <RedeemOutlinedIcon />
        }
    },
    {
        path: "orders",
        element: <OrdersPage />,
        state: "Orders",
        sidebarProps: {
            displayText: "Orders",
            icon: <AccountBalanceWalletIcon />
        },
    },
    {
        path: "order_details/:orderId",
        element: <OrderDetailsPage />,
        state: "OrderDetails",
    },
    {
        path: "topup",
        element: <TopUpPage />,
        state: "TopUp",
    },
    {
        path: "profile",
        element: <UserProfileDetails />,
        state: "Profile",
    },
    {
        path: "edit_profile",
        element: <EditProfile />,
        state: "EditProfile",
    },
    {
        path: "introduction",
        element: <IntroductionPage />,
        state: "Introduction",
    },
    {
        path: "rewardpoints",
        element: <RewardPointsPage />,
        state: "Reward Points",
        sidebarProps: {
            displayText: "Reward Points",
            icon: <StarsIcon />
        },
    },
    {
        path: "faqs",
        element: <FAQs />,
        state: "FAQs",
        sidebarProps: {
            displayText: "FAQs",
            icon: <QuizIcon />
        },
    },
    {
        path: "card_details/:cardId",
        element: <CardDetailsPage />,
        state: "CardDetails",
    },
    {
        path: "payment_success",
        element: <PaymentSuccessPage />,
        state: "PaymentSuccess",
    },
];

export default appRoutes;