import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Box, useMediaQuery, useTheme, Container } from '@mui/material';
import { capitalizeFirstLetter } from '../../../configs/fontConfigs';
import { categoryIconStyle, mobileMenuStyles } from '../../../styles/PublicPage/menu';
import Menu from '../../../api/MenuAPI';

const MobileMenu: React.FC<{ slug: string }> = ({ slug }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const { data, selectedCategory, setSelectedCategory } = Menu(slug);

  const prependImageUrl = (url: string) => {
    return `https://shake-salad-production.s3.ap-southeast-1.amazonaws.com/${url}`;
  };

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    console.log('Category Clicked:', category);
  };

  const categoryImages: { [category: string]: string } = {};
  data.forEach(item => {
    if (!categoryImages[item.main_tag_name]) {
      categoryImages[item.main_tag_name] = prependImageUrl(item.featured_image_url);
    }
  });
  
  const filteredData = data.filter(item => item.main_tag_name === selectedCategory);
  const selectedCategoryItem = data.find(item => item.main_tag_name === selectedCategory);

  return (
    <Container
      maxWidth="lg"
      sx={{ maxWidth: { lg: '1280px !important' }, padding: '0 !important' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', p: 0 }}>
        <Box sx={mobileMenuStyles.categorySidebar(isMobile, isTablet)}>
          <Box>
            {Array.from(new Set(data.map(item => item.main_tag_name))).map(category => (
              <Box
                key={category}
                onClick={() => handleCategoryClick(category)}
                sx={mobileMenuStyles.categoryBox(selectedCategory, category)}
              >
                <img
                  src={categoryImages[category]}
                  alt={category}
                  style={categoryIconStyle}
                />
                <Typography variant="subtitle2" sx={mobileMenuStyles.categoryText}>
                  {capitalizeFirstLetter(category)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1, width: isMobile ? '80%' : '75%', p: 0 }}>
          {selectedCategoryItem && selectedCategoryItem.promo_featured_image_url && (
            <Box sx={{ mb: 2 }}>
              <img
                src={prependImageUrl(selectedCategoryItem.promo_featured_image_url)}
                alt={`${selectedCategory} promo`}
                style={{ width: '100%' }}
              />
            </Box>
          )}
          <Box sx={mobileMenuStyles.contentBox}>
            <Typography variant="h6" sx={mobileMenuStyles.categoryTitle}>
              {capitalizeFirstLetter(selectedCategory)}
            </Typography>
            <Grid container spacing={1}>
              {filteredData.map(item => (
                <Grid item xs={6} sm={4} key={item.id}>
                  <Card sx={mobileMenuStyles.card}>
                    <CardMedia
                      component="img"
                      height="150"
                      image={prependImageUrl(item.featured_image_url)}
                      sx={mobileMenuStyles.cardMedia}
                      onLoad={() => console.log('Image Loaded:', item.featured_image_url)}
                      onError={() => console.log('Error Loading Image:', item.featured_image_url)}
                    />
                    <CardContent sx={mobileMenuStyles.cardContent}>
                      <Typography sx={mobileMenuStyles.cardTitle}>
                        {item.name}
                      </Typography>
                      <Typography sx={mobileMenuStyles.cardPrice}>
                        $ {item.price.toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default MobileMenu;
