const voucherPageStyles = {
  container: {
    p: 2,
  },
  title: {
    mb: 2,
  },
  card: {
    backgroundColor: '#4caf50',
    borderRadius: '5px',
    boxShadow: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'visible',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      right: '-20px',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundColor: '#f5f5f5',
      transform: 'translateY(-50%)',
    },
  },
  cardContent: {
    display: 'flex',
    width: '100%',
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    borderStyle: 'dashed',
    margin: '0px 15px 0px 15px',
    borderColor: '#ffffff',
    borderWidth: '1px',
  },
  couponName: {
    color: '#fff',
    fontSize: '16px',
  },
  couponDetails: {
    color: '#fff',
  },
  discountText: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  minimumSpendingText: {
    fontSize: '12px',
    color: '#fff',
  },
};

export default voucherPageStyles;
