import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserBasicInfo } from "../../../models/IUser";
import { IAuthApiState } from "../../../models/IAuthState";
import { login, logout, register } from "./AuthThunk";
import { toast } from "../../../components/common/SnackBar/snackBar";

const initialState: IAuthApiState = {
    basicUserInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo") as string)
      : null,
    // for now not using userProfileData
    userProfileData: undefined,
    status: "idle",
    error: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(login.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(login.fulfilled, (state, action: PayloadAction<IUserBasicInfo>) => {
          state.status = "idle";
          state.basicUserInfo = action.payload;
        })
        .addCase(login.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message || "Login failed";
        })
        .addCase(register.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(register.fulfilled, (state, action: PayloadAction<IUserBasicInfo>) => {
          state.status = "idle";
          state.basicUserInfo = action.payload;
          toast.success("Register Successful!");

        })
        .addCase(register.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message || "Registration failed";
          toast.error("Register Failed!");

        })
        .addCase(logout.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(logout.fulfilled, (state) => {
          state.status = "idle";
          state.basicUserInfo = null;
        })
        .addCase(logout.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message || "Logout failed";
        });
    },
  });
  
  export default authSlice.reducer;