import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Button, Paper, IconButton, InputAdornment } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { toast } from '../../../components/common/SnackBar/snackBar';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { sendOtpForSignup } from '../../../api/AuthAPI';
import HeaderTopBar from '../../../components/common/Header/HeaderTopBar';
import Footer from '../../common/Footer/Footer';
import registerPageStyles from '../../../styles/PublicPage/register';

const RegisterPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const UserRegisterData = location.state || {};

    const [phone, setPhone] = useState(UserRegisterData.phone || "");
    const [countryCode, setCountryCode] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [password, setPassword] = useState(UserRegisterData.password || "");
    const [showPassword, setShowPassword] = useState(false);
    const [firstname, setFirstName] = useState(UserRegisterData.firstname || "");
    const [lastname, setLastName] = useState(UserRegisterData.lastname || "");

    const handlePhoneChange = (value: string, data: any) => {
        const phoneNumber = value.slice(data.dialCode.length); 
        setPhone(phoneNumber);

        setCountryCode(`+${data.dialCode}`);
    };

    const handleSendOtp = async () => {
        const phoneNumber = phone.replace(/[^0-9]/g, "");

        if (phoneNumber && countryCode) {
            try {
                const response = await sendOtpForSignup(`${countryCode}${phoneNumber}`);
                if (response.success) {
                    setOtpSent(true);
                    toast.success("OTP sent successfully!");
                    navigate('/verify-otp', {
                        state: { country_code: countryCode, mobile: phoneNumber, password, firstname, lastname }
                    });
                } else {
                    toast.error("Failed to send OTP.");
                }
            } catch (error) {
                toast.error("An unknown error occurred.");
            }
        } else {
            toast.error("Invalid phone number.");
        }
    };

    const handleRegister = async () => {
        if (!otpSent) {
            handleSendOtp();
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Box sx={registerPageStyles.container}>
                <HeaderTopBar />
                <Container maxWidth="xs" sx={registerPageStyles.contentContainer}>
                    <Paper elevation={0} sx={registerPageStyles.paper}>
                        <Typography variant="h4" component="h1" align="center" gutterBottom>
                            Create new Account
                        </Typography>
                        <Typography variant="body2" align="center" gutterBottom>
                            Already Registered? <Link to="/login">Log in here</Link>.
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                required
                                fullWidth
                                id="firstname"
                                name="firstname"
                                label="First Name"
                                type="text"
                                value={firstname}
                                onChange={(e) => setFirstName(e.target.value)}
                                sx={registerPageStyles.textField}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="lastname"
                                name="lastname"
                                label="Last Name"
                                type="text"
                                value={lastname}
                                onChange={(e) => setLastName(e.target.value)}
                                sx={registerPageStyles.textField}
                            />
                            <PhoneInput
                                country={'sg'}
                                value={countryCode + phone}
                                onChange={handlePhoneChange}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true,
                                }}
                                containerStyle={registerPageStyles.phoneInput}
                                inputStyle={{ width: '100%' }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={registerPageStyles.textField}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={registerPageStyles.button}
                                onClick={handleRegister}
                            >
                                Sign up
                            </Button>
                        </Box>
                    </Paper>
                </Container>
                <Footer />
            </Box>
        </>
    );
};

export default RegisterPage;