import React from 'react';
import { Box, Card, Typography, Grid } from '@mui/material';
import useSWR from 'swr';
import { getRewardPointBalance, getRewardPoints } from '../../../api/RewardPoint';
import { PATH_NAME } from '../../../configs/pathName';

const RewardPointsPage: React.FC = () => {
  const { data: rewardPoints, error: pointsError, isLoading: pointsLoading } = useSWR(PATH_NAME.API_USER_GET_REWARD_POINTS, getRewardPoints);
  const { data: balance, error: balanceError, isLoading: balanceLoading } = useSWR(PATH_NAME.API_USER_GET_REWARD_POINTS_BALANCE, getRewardPointBalance);

  if (pointsLoading || balanceLoading) return <div>Loading...</div>;
  if (pointsError || balanceError) return <div>Error loading reward points or balance</div>;

  return (
    <Box sx={{ p: 3, backgroundColor: '#F0F5F4', minHeight: '100vh' }}>   
      <Card sx={{ p: 3, textAlign: 'center', backgroundColor: '#ffffff', borderRadius: 2, mb: 2 }}>
        <Typography variant="body1" color="textSecondary">Your Balance</Typography>
        <Typography variant="h2" color="#4CAF50" fontWeight="bold">
          {balance}
        </Typography>
        <Typography variant="body2" color="textSecondary">Points</Typography>
        {/* <Button variant="contained" sx={{ mt: 2, backgroundColor: '#4CAF50', borderRadius: 20, px: 4, textTransform: 'none' }}>
          Redeem
        </Button> */}
      </Card>

      <Grid container spacing={1}>
        {rewardPoints && rewardPoints.length > 0 ? (
          rewardPoints.map((point: any) => (
            <Grid item xs={12} key={point.id}>
              <Card sx={{ p: 2, backgroundColor: '#ffffff', borderRadius: 2, display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box
                  sx={{
                    width: 6,
                    height: 45,
                    backgroundColor: point.points >= 0 ? '#4CAF50' : '#FF7043',
                    borderRadius: 1,
                    mr: 2,
                  }}
                />
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {point.points >= 0 ? 'Earned' : 'Redeemed'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {new Date(point.transaction_at).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {point.description}
                  </Typography>
                </Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: point.points >= 0 ? '#4CAF50' : '#FF7043' }}>
                  {point.points} Points
                </Typography>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', mt: 2 }}>
            No reward points available
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default RewardPointsPage;
