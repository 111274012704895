import { Container, Box, Typography, Button, Paper, Link as MuiLink } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../redux/features/redux-hooks";
import { register } from '../../../redux/features/Auth/AuthThunk';
import { toast } from '../../../components/common/SnackBar/snackBar';
import ShakeSaladLogo from '../../../assets/images/shakesaladlogo.png';
import { MuiOtpInput } from 'mui-one-time-password-input';
import verifyOtpPageStyles from '../../../styles/PublicPage/verifyotppage';
import { sendOtp } from '../../../api/AuthAPI';
import { PATH_NAME } from '../../../configs/pathName';

const VerifyOtpPage = () => {
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const [otp, setOtp] = useState("");
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(true);
    const [countdownStarted, setCountdownStarted] = useState(false);

    useEffect(() => {
        if (countdownStarted && timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        } else if (timer === 0) {
            setCanResend(true);
            setCountdownStarted(false);
        }
    }, [timer, countdownStarted]);

    const handleVerifyOtp = async () => {
        if (otp) {
            try {
                const { country_code, mobile } = state;

                const data = {
                    country_code,
                    mobile,
                    password: state.password,
                    firstname: state.firstname,
                    lastname: state.lastname,
                    otp,
                };

                await dispatch(register(data)).unwrap();
                navigate(PATH_NAME.INTRODUCTION);
            } catch (error) {
                toast.error("OTP verification failed.");
            }
        } else {
            toast.error("Please enter OTP.");
        }
    };

    const handleResendOtp = async () => {
        try {
            const { country_code, mobile } = state;
            const full_mobile = `${country_code}${mobile}`;
    
            await sendOtp(full_mobile);
            setTimer(30);
            setCanResend(false);
            setCountdownStarted(true);
            toast.success("OTP resent successfully!");
        } catch (error) {
            toast.error("Failed to resend OTP. Please try again.");
        }
    };
    
    const handleEditNumber = () => {
        navigate('/register', {
            state: {
                country_code: state.country_code,
                mobile: state.mobile,
                password: state.password,
                firstname: state.firstname,
                lastname: state.lastname,
            }
        });
    };

    return (
        <Box component="section" sx={verifyOtpPageStyles.section}>
            <Container maxWidth="xs">
                <Paper elevation={0} sx={verifyOtpPageStyles.paper}>
                    <Box sx={verifyOtpPageStyles.logoContainer}>
                        <img
                            alt="Company Logo"
                            src={ShakeSaladLogo}
                            style={verifyOtpPageStyles.logoImage}
                        />
                    </Box>
                    <Typography
                        variant="h5"
                        component="h1"
                        align="center"
                        gutterBottom
                        sx={verifyOtpPageStyles.title}
                    >
                        OTP Verification
                    </Typography>
                    <Typography
                        variant="body1"
                        align="center"
                        gutterBottom
                        sx={verifyOtpPageStyles.subtitle}
                    >
                        We will send you a <strong>One Time Password</strong> on your phone number {state.country_code + state.mobile}.&nbsp;
                        <MuiLink component="button" onClick={handleEditNumber}>
                            Edit Number
                        </MuiLink>
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <MuiOtpInput
                            length={6}
                            value={otp}
                            onChange={setOtp}
                            TextFieldsProps={{
                                inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
                                placeholder: '',
                            }}
                            sx={verifyOtpPageStyles.otpInput}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={verifyOtpPageStyles.button}
                            onClick={handleVerifyOtp}
                        >
                            Verify OTP
                        </Button>
                        <Typography align="center" sx={verifyOtpPageStyles.resendText}>
                            Didn’t receive the OTP?{' '}
                            {canResend ? (
                                <MuiLink component="button" onClick={handleResendOtp}>
                                    Resend OTP
                                </MuiLink>
                            ) : (
                                <span>Resend OTP in {timer} seconds</span>
                            )}
                        </Typography>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

export default VerifyOtpPage;