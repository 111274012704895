import { SxProps, Theme } from '@mui/material/styles';

export const containerStyle = { 
  mt: 4, 
  mb: 4 
};

export const titleStyle = { 
  mb: 4, 
  fontWeight: 'bold', 
  color: '#12582D' 
};

export const categoryContainerStyle = { 
  mb: 4 
};

export const categoryItemStyle = (isSelected: boolean): SxProps<Theme> => ({
  cursor: 'pointer',
  textAlign: 'center',
  p: 2,
  borderBottom: isSelected ? '2px solid #12582D' : '2px solid transparent',
  fontWeight: isSelected ? 'bold' : 'normal',
});

export const categoryIconStyle: React.CSSProperties = { 
  width: 50, 
  height: 50, 
  objectFit: 'contain' 
};

export const selectedCategoryTitleStyle = { 
  mb: 2, 
  fontWeight: 'bold', 
  color: '#12582D', 
  borderStyle: 'solid', 
  borderWidth: '0px 0px 0px 4px', 
  pl: '15px' 
};

export const cardStyle = { 
  height: '100%', 
  display: 'flex', 
  flexDirection: 'column', 
  boxShadow: 0 
};

export const cardMediaStyle = { 
  objectFit: 'contain', 
  borderRadius: '8px' 
};

export const cardContentStyle = { 
  textAlign: 'left' 
};

export const cardTitleStyle = { 
  fontWeight: 600 
};

export const mobileMenuStyles = {
  container: (isMobile: boolean, isTablet: boolean): SxProps<Theme> => ({
    display: 'flex',
    flexDirection: 'row',
    width: isMobile ? '80%' : '75%',
    p: 0,
  }),
  categorySidebar: (isMobile: boolean, isTablet: boolean): SxProps<Theme> => ({
    width: isMobile ? '20%' : isTablet ? '15%' : '20%',
    position: 'sticky',
    top: '64px',
    height: 'calc(100vh - 64px)',
    borderRight: '1px solid #ddd',
    backgroundColor: '#f9f9f9',
    overflowY: 'auto',
  }),
  categoryBox: (selectedCategory: string, category: string): SxProps<Theme> => ({
    cursor: 'pointer',
    p: 1,
    textAlign: 'center',
    borderLeft: selectedCategory === category ? '3px solid #12582D' : '3px solid transparent',
    fontWeight: selectedCategory === category ? 'bold' : 'normal',
    color: selectedCategory === category ? '#12582D' : '#000000',
  }),
  categoryText: {
    fontSize: '10px',
    fontWeight: 600,
    mt: 1,
  },
  promoImage: {
    width: '100%',
    mb: 2,
  },
  contentBox: {
    p: '16px',
  },
  categoryTitle: {
    mb: 2,
    fontWeight: 'bold',
    color: '#12582D',
    borderStyle: 'solid',
    borderWidth: '0px 0px 0px 4px',
    pl: '15px',
    lineHeight: 1,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 0,
  },
  cardMedia: {
    objectFit: 'contain',
  },
  cardContent: {
    textAlign: 'center',
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: '12px',
  },
  cardPrice: {
    fontWeight: 500,
    fontSize: '14px',
  },
};