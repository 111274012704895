import axios from 'axios';
import { BACKEND_BASE_URL } from '../configs/constants';

// Function to get the token from localStorage
const getToken = () => localStorage.getItem('token');

// Axios instance with the base URL and a request interceptor to add the Authorization header
const axiosInstance = axios.create({
  baseURL:BACKEND_BASE_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
