import axiosInstance from './axiosInstance';
import { PATH_NAME } from '../configs/pathName';
import { GeneratePaymentPayload, LinkCardPayload, IPaymentMethod, UnlockFridgePayload } from '../models/IWallet';

export const generatePayment = async (payload: GeneratePaymentPayload): Promise<{ result: string }> => {
  const response = await axiosInstance.post(PATH_NAME.API_PAYMENT_PAYNOW, payload);
  return response.data;
};

export const linkCard = async (payload: LinkCardPayload): Promise<{ result: { webPaymentUrl: string } }> => {
  const response = await axiosInstance.post(PATH_NAME.API_PAYMENT_REQUEST_TOKEN, payload);
  return response.data;
};

export const startTransaction = async (): Promise<{ transaction_ref: string, id: number }> => {
  const response = await axiosInstance.post(PATH_NAME.API_TRANSACTION_START);
  return response.data.result;
};

export const fetchPaymentMethods = async (): Promise<IPaymentMethod[]> => {
  const response = await axiosInstance.get(PATH_NAME.API_GET_MY_PAYMENT_METHODS);
  return response.data.result;
};

export const deletePaymentMethods = async (userId: string): Promise<{ result: IPaymentMethod[] }> => {
  const response = await axiosInstance.delete(PATH_NAME.API_DELETE_PAYMENT_METHOD, {
    data: { userId },
  });
  return response.data;
};

export const unlockFridge = async (payload: UnlockFridgePayload): Promise<void> => {
  try {
    await axiosInstance.post(PATH_NAME.API_MACHINE_LOGIN, payload);
  } catch (error) {
    console.error("Error unlocking the fridge:", error);
  }
};