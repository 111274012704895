import axiosInstance from '../api/axiosInstance';
import { handleError } from '../helpers/Fetchers/fetchers';

export const GetUserCoupon = async (key: string) => {
    try {
      const response = await axiosInstance.get(key);
      if (response.data && response.data.result && Array.isArray(response.data.result.coupons)) {
        return response.data.result.coupons;
      } else {
        console.error('Invalid response structure for generalItemFetcher:', response.data);
        throw new Error('Invalid response structure');
      }
    } catch (error) {
      handleError(error, 'fetching coupons');
      throw new Error('Failed to fetch coupons');
    }
  };