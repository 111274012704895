import { Container, Typography, Box, Card, CardContent, Divider } from "@mui/material";
import useSWR from 'swr';
import { PATH_NAME } from "../../../configs/pathName";
import { generalFetcher, generalItemFetcher } from "../../../helpers/Fetchers/fetchers";
import { IOrder, IPayment } from "../../../models/IWallet";
import { format } from "date-fns";
import { OdersPageStyles } from '../../../styles/ProtectedPage/orders';
import { Link } from 'react-router-dom';

const OrdersPage = () => {
  const { data: userProfile } = useSWR(PATH_NAME.API_AUTH_PROFILE, generalFetcher);
  const { data: orderHistory } = useSWR(
    userProfile ? PATH_NAME.API_GET_ORDER_BY_USER_ID : null,
    generalItemFetcher
  );

  if (!userProfile || !orderHistory) {
    return <div>Loading...</div>;
  }

  const totalSpend = orderHistory.reduce((sum: number, order: IOrder) => {
    return sum + order.payment_amount;
  }, 0);

  const groupedOrders = orderHistory.reduce((groups: Record<string, IOrder[]>, order: IOrder) => {
    const orderDate = new Date(order.created_at);
    let groupKey = format(orderDate, "dd MMMM yyyy");

    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(order);

    return groups;
  }, {});

  const sortedDates = Object.keys(groupedOrders).sort((b, a) => new Date(a).getTime() - new Date(b).getTime());

  return (
    <Container>
      <Box sx={OdersPageStyles.containerBox}>
        <Card sx={OdersPageStyles.card}>
          <CardContent sx={OdersPageStyles.cardContentLeft}>
            <Typography variant="h6">{orderHistory.length}</Typography>
            <Typography variant="subtitle1">Total Orders</Typography>
          </CardContent>
        </Card>

        <Card sx={OdersPageStyles.card}>
          <CardContent sx={OdersPageStyles.cardContentLeft}>
            <Typography variant="h6">SGD {totalSpend.toFixed(2)}</Typography>
            <Typography variant="subtitle1">Total Spend</Typography>
          </CardContent>
        </Card>
      </Box>

      <Box sx={OdersPageStyles.transactionBox}>
        <Typography variant="h6" gutterBottom>Transactions History</Typography>
        <Card sx={OdersPageStyles.transactionCard}>
          <CardContent>
            {sortedDates.map((date) => (
              <Box key={date} sx={OdersPageStyles.transactionDateBox}>
                <Typography variant="subtitle1" sx={OdersPageStyles.transactionDate}>
                  {date}
                </Typography>

                {groupedOrders[date].map((order: IOrder) => (
  <Box key={order.id} sx={OdersPageStyles.orderBox}>
    <Box sx={OdersPageStyles.orderDetailsBox}>
      <Box>
        <Typography variant="h6" sx={OdersPageStyles.orderIdText}>
          <Link to={`${PATH_NAME.ORDER_DETAILS}${order.id}`}>
            Order {order.id}
          </Link>
        </Typography>
        <Typography variant="body2" sx={OdersPageStyles.orderItemsText}>
          Items: {order.items.length}
        </Typography>
      </Box>
      <Box sx={OdersPageStyles.paymentAmountText}>
        <Typography variant="body1">- SGD {order.payment_amount.toFixed(2)}</Typography>
        <Typography variant="body2" sx={OdersPageStyles.paymentDetailsText}>
          {order.payments.map((p: IPayment) => p.type).join(', ')}
        </Typography>
      </Box>
    </Box>
    <Divider />
  </Box>
))}
              </Box>
            ))}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default OrdersPage;