import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { footerStyles } from '../../../styles/PublicPage/footer';
const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();

  return (
    <Box sx={footerStyles}>
      <Typography variant="body2">
        &copy; {currentYear} ShakeSalad. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;