import { createAsyncThunk } from "@reduxjs/toolkit";
import { INewUser, IUser } from "../../../models/IUser";
import { 
    Register as RegisterAPI, 
    Login as LoginAPI, 
    UserProfile as UserProfileAPI, 
    resetPasswordAPI 
} from "../../../api/AuthAPI";

export const login = createAsyncThunk("login", async (data: IUser, { dispatch }) => {
    try {

        await dispatch(logout());

        const resData = await LoginAPI(data); 
        if (resData.success && resData.access_token) {
            localStorage.setItem("token", resData.access_token);

            const resUserData = await UserProfileAPI();
            if (resUserData.success && resUserData.result) {
                localStorage.setItem("userInfo", JSON.stringify(resUserData.result || {}));
                return resUserData.result || {};
            }
        } else {
            throw new Error(resData.error_message || "Login failed");
        }
    } catch (error: any) {
        throw new Error("Login failed: " + error.message);
    }
});

export const logout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
    try {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("token");
        return true;
    } catch (error) {
        return rejectWithValue('Failed to clear user data');
    }
});

export const register = createAsyncThunk("register", async (data: INewUser, { dispatch }) => {
    try {
        
        await dispatch(logout());

        const resData = await RegisterAPI(data);
        
        if (resData.success) {
            console.log("Registered Successfully", resData);

            if(resData.result){
                localStorage.setItem("token", resData.result.access_token);
                const resUserData = await UserProfileAPI();
                if (resUserData.success && resUserData.result) {
                    localStorage.setItem("userInfo", JSON.stringify(resUserData.result || {}));
                    return resUserData.result || {};
                }
            } else {
                throw new Error(resData.error_message || "Failed to fetch user profile");
            }
        } else {
            throw new Error(resData.error_message || "Registration Failed");
        }
    } catch (error:any) {
        throw new Error("Registration failed:" + error.message);
    }
});

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ new_password, otp, full_mobile }: { new_password: string; otp: string; full_mobile: string }, { rejectWithValue }) => {
        try {
            const response = await resetPasswordAPI({ new_password, otp, full_mobile });
            
            if (response.success) {
                return response.result;
            } else {
                throw new Error(response.error_message || "Failed to reset password");
            }
        } catch (error: any) {
            return rejectWithValue(error.message || "Failed to reset password");
        }
    }
);