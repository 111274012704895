import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { IHeaderTopBar } from '../../../models/IResponsiveTopBar';
import assets from '../../../assets';

const ResponsiveTopBar: React.FC<IHeaderTopBar> = ({ isMobile }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userToken, setUserToken] = useState<string | null>(null);
  const navigate = useNavigate();

  const navItems = [
    { label: 'About Us', path: '/about' },
    { label: 'Contact', path: '/contact' },
    { label: userToken ? 'Dashboard' : 'Login', path: userToken ? '/member' : '/login' },
    { label: 'Register', path: '/register' },
  ];

  useEffect(() => {
    setUserToken(localStorage.getItem('token'));
  }, []);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const handleNavigation = (path: string) => () => {
    navigate(path);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <img
          onClick={handleNavigation('/')}
          src={assets.images.logo}
          alt="Logo"
          style={{ height: '50px' }}
        />
        <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
          <MenuIcon sx={{ color: 'black' }} />
        </IconButton>
      </Box>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
            {navItems.map((item, index) => (
              <ListItem key={index} onClick={handleNavigation(item.path)}>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default ResponsiveTopBar;
