import React, { useState, useEffect } from 'react';
import { Container, Box, Button, Avatar, TextField, IconButton, MenuItem, Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IUserUpdateData } from '../../../models/IUser';
import { updateUserProfileAPI } from '../../../api/AuthAPI';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { PATH_NAME } from '../../../configs/pathName';
import useSWR from 'swr';
import { generalFetcher } from '../../../helpers/Fetchers/fetchers';

const EditProfile = () => {
  const navigate = useNavigate();

  const { data: basicUserInfo } = useSWR(PATH_NAME.API_AUTH_PROFILE, generalFetcher);

  const [profileData, setProfileData] = useState<IUserUpdateData>({
    firstname: '',
    lastname: '',
    birth_year: 0,
    birth_month: 0,
    email: '',
    mobile: '',
    country_code: '',
    full_mobile: '',
    email_consent: false,
    sms_consent: false, 
  });

  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (basicUserInfo) {
      setProfileData({
        ...basicUserInfo,
      });
    }
  }, [basicUserInfo]);

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      const { country_code, mobile, full_mobile, ...updatableFields } = profileData;
      await updateUserProfileAPI({
        ...updatableFields,
        mobile,
        country_code,
        full_mobile,
      });
      localStorage.setItem('userInfo', JSON.stringify(profileData));
      navigate(PATH_NAME.PROFILE);
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setProfileData({ ...profileData, [name]: checked });
  };

  return (
    <Container sx={{ maxWidth: '400px', padding: 4, backgroundColor: '#ffffff', borderRadius: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 3 }}>
        <Box sx={{ position: 'relative' }}>
          <Avatar
            src={profilePicture ? URL.createObjectURL(profilePicture) : undefined}
            sx={{ width: 100, height: 100 }}
          />
          <IconButton
            component="label"
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              backgroundColor:'green',
              boxShadow: 2,
              color:'#fff',
            }}
          >
            <CameraAltIcon />
            <input
              type="file"
              hidden
              onChange={(e) => setProfilePicture(e.target.files ? e.target.files[0] : null)}
            />
          </IconButton>
        </Box>
      </Box>
      
      <TextField
        label="First Name"
        value={profileData.firstname}
        onChange={(e) => setProfileData({ ...profileData, firstname: e.target.value })}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Last Name"
        value={profileData.lastname}
        onChange={(e) => setProfileData({ ...profileData, lastname: e.target.value })}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        value={profileData.email}
        onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
        fullWidth
        margin="normal"
      />
      
      <PhoneInput
        value={profileData.full_mobile}
        containerStyle={{ width: '100%' }}
        inputStyle={{ width: '100%', backgroundColor: 'transparent' }}
        disabled
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <TextField
          select
          label="Birth Year"
          value={profileData.birth_year}
          onChange={(e) => setProfileData({ ...profileData, birth_year: parseInt(e.target.value) })}
          fullWidth
          margin="normal"
        >
          {[...Array(100)].map((_, i) => (
            <MenuItem key={i} value={new Date().getFullYear() - i}>
              {new Date().getFullYear() - i}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Birth Month"
          value={profileData.birth_month}
          onChange={(e) => setProfileData({ ...profileData, birth_month: parseInt(e.target.value) })}
          fullWidth
          margin="normal"
        >
          {[...Array(12)].map((_, i) => (
            <MenuItem key={i} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <FormControlLabel
        control={
          <Checkbox
            checked={profileData.email_consent}
            onChange={handleCheckboxChange}
            name="email_consent"
          />
        }
        label="Email Consent"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={profileData.sms_consent}
            onChange={handleCheckboxChange}
            name="sms_consent"
          />
        }
        label="SMS Consent"
      />

      <Button
        variant="contained"
        fullWidth
        onClick={handleSaveChanges}
        disabled={isLoading}
        sx={{ 
          marginTop: 3,
          backgroundColor: 'green', 
          color: 'white', 
          '&:hover': { backgroundColor: 'darkgreen' },
        }}
      >
        Update Profile
      </Button>
    </Container>
  );
};

export default EditProfile;