import { INewUser, IUser, IUserUpdateData } from "../models/IUser";
import axiosInstance from "../api/axiosInstance";
import { PATH_NAME } from '../configs/pathName';
import { toast } from '../components/common/SnackBar/snackBar';
import { handleError } from '../helpers/Fetchers/fetchers';

export const Login = async (data: IUser) => {
    try {
        const response = await axiosInstance.post(PATH_NAME.API_AUTH_LOGIN, data);
        if (response.status === 200) {
            return response.data;
        } else {
            toast.error("Unexpected status code: " + response.status);
        }
    } catch (error: any) {
        const errorMessage = error.response?.data?.error_message || "Login Unsuccessful";
        toast.error(errorMessage);
    }
    return null;
};

export const Register = async (data: INewUser) => {
    try {
        const response = await axiosInstance.post(PATH_NAME.API_AUTH_SIGN_UP, data);
        if (response.status === 200) {
            return response.data;
        } else {
            toast.error("Unexpected status code: " + response.status);
        }
    } catch (error: any) {
        const errorMessage = error.response?.data?.error_message || "Register Unsuccessful";
        toast.error(errorMessage);
    }
    return null;
};

export const UserProfile = async () => {
    try {
        const response = await axiosInstance.get(PATH_NAME.API_AUTH_PROFILE);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Unexpected status code: " + response.status);
        }
    } catch (error) {
        handleError(error,"UserProfile API error:");
    }
};

export const sendOtp = async (full_mobile: string) => {
    try {
        const response = await axiosInstance.post(PATH_NAME.API_AUTH_SEND_OTP, {
            full_mobile,
        });
        return response.data;
    } catch (error) {
        throw new Error("Failed to send OTP.");
    }
};

export const sendOtpForSignup = async (full_mobile: string) => {
    try {
        const response = await axiosInstance.post(PATH_NAME.API_AUTH_SEND_OTP, {
            full_mobile,
        });
        return response.data;
    } catch (error) {
        throw new Error("Failed to send OTP.");
    }
};

export const resetPasswordAPI = async ({ new_password, otp, full_mobile }: { new_password: string; otp: string; full_mobile: string }) => {
    const response = await axiosInstance.post(PATH_NAME.API_AUTH_RESET_PASSWORD, { new_password, otp, full_mobile });
    return response.data;
};

export const updateUserProfileAPI = async (data: IUserUpdateData) => {
    try {
        const response = await axiosInstance.put(PATH_NAME.API_UPDATE_USER_INFO, data);
        if (response.status === 200) {
            toast.success("Profile updated successfully");
            return response.data;
        } else {
            toast.error("Unexpected status code: " + response.status);
        }
    } catch (error) {
        handleError(error, "Update User Profile API error:");
        toast.error("Failed to update profile");
    }
};