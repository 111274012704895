import { colors } from "@mui/material";

const colorConfigs = {

    primary: "#12582D",          // Primary color
    secondary: "#000000",        // Secondary color
    primaryActiveBg: "#346c43",  // Primary active background

    sidebar: {
        bg: "#12582D",
        color: "#eeeeee",
        hoverBg: "#12582D",
        activeBg: "#346c43"
    },
    topbar: {
        bg: "#fff",
        color: "#000"
    },
    mainBg: colors.grey["100"],
    pagination: {
        color: "#000000",
        activeBg: "#12582D!important",
        activeColor: "#ffffff"
    }
};

export default colorConfigs;