import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../redux/features/redux-hooks";

const DefaultLayout = () => {
  const basicUserInfo = useAppSelector((state) => state.authState.basicUserInfo);

  const urlParams = new URLSearchParams(window.location.search);
  const sessionRef = urlParams.get("session_ref");
  const machineId = urlParams.get("machine_id");

  if (sessionRef && machineId) {
      const sessionTime = Date.now();
      localStorage.setItem("session_ref", sessionRef);
      localStorage.setItem("session_time", sessionTime.toString());
      localStorage.setItem("machine_id", machineId);
  }

  if (basicUserInfo) {
    return <Navigate replace to={"/member"} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default DefaultLayout;