const homePageStyles = {
  Box: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    flexGrow: 1,
    maxWidth: '1140px',
    margin: '0 auto',
    width: '100%',
    padding: '20px',
  },
  header: {
    textAlign: 'center',
    margin: '40px 0',
    fontFamily: 'Roboto, sans-serif',
  },
  subHeader: {
    marginBottom: '20px',
    fontFamily: 'Roboto, sans-serif',
  },
  paragraph: {
    marginBottom: '40px',
    lineHeight: '1.8',
  },
};

export default homePageStyles;