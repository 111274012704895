import React, { useState } from 'react';
import { Box, Container, CssBaseline, Typography, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { toast } from '../../common/SnackBar/snackBar';
import HeaderTopBar from '../../common/Header/HeaderTopBar';
import Footer from '../../common/Footer/Footer';
import ForgetPasswordPageStyles from '../../../styles/PublicPage/resetPassword';
import { sendOtp } from '../../../api/AuthAPI';

const ForgetPasswordPage: React.FC = () => {
    const navigate = useNavigate();
    const [phone, setPhone] = useState("");

    const handleSendOtp = async () => {
        const fullMobile = `+${phone.match(/\d+/)?.[0]}`;
    
        if (fullMobile) {
            try {
                const response = await sendOtp(fullMobile);
                if (response.success) {
                    toast.success("OTP sent successfully!");
                    navigate('/reset-password-verify', { state: { phone: fullMobile } });
                } else {
                    toast.error("Failed to send OTP.");
                }
            } catch (error) {
                toast.error("An unknown error occurred.");
            }
        } else {
            toast.error("Please enter a valid phone number.");
        }
    };
    
    return (
        <>
            <Box sx={ForgetPasswordPageStyles.container}>
                <HeaderTopBar />
                <Container maxWidth="xs" sx={ForgetPasswordPageStyles.contentContainer}>
                    <CssBaseline />
                    <Typography variant="h5" component="h1" gutterBottom align="left" sx={ForgetPasswordPageStyles.headerText}>
                        Reset Your Password
                    </Typography>
                    <Typography variant="body1" gutterBottom align="left" sx={ForgetPasswordPageStyles.descriptionText}>
                        Enter your phone number to receive an OTP for resetting your password.
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Box sx={ForgetPasswordPageStyles.phoneInputContainer}>
                            <PhoneInput
                                country={'sg'}
                                value={phone}
                                onChange={setPhone}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true,
                                }}
                                containerStyle={{ width: '100%' }}
                                inputStyle={{ width: '100%' }}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                sx={ForgetPasswordPageStyles.sendOtpButton}
                                onClick={handleSendOtp}
                            >
                                Send OTP
                            </Button>
                            <Typography variant="body2" align="center">
                                Remembered your password?{' '}
                                <Link to={'/login'} style={ForgetPasswordPageStyles.loginLink}>
                                    Log in
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Container>
                <Footer />
            </Box>
        </>
    );
};

export default ForgetPasswordPage;