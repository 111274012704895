export const OdersPageStyles = {
    containerBox: {
      textAlign: 'center',
      mt: 4,
      display: 'flex',
      justifyContent: 'center',
      gap: 2,
    },
    card: {
      mb: 0,
      padding: 0,
      backgroundColor: '#ffffff',
      color: '#000',
      flex: 1,
    },
    cardContentLeft: {
      textAlign: 'left',
    },
    transactionBox: {
      mt: 4,
    },
    transactionCard: {
      padding: 0,
      backgroundColor: '#ffffff',
    },
    transactionDateBox: {
      mb: 4,
    },
    transactionDate: {
      mb: 2,
      fontWeight: 'bold',
    },
    orderBox: {
      mb: 2,
    },
    orderDetailsBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      pb:1,
    },
    orderIdText: {
      fontSize: '16px',
    },
    orderItemsText: {
      color: 'textSecondary',
    },
    paymentAmountText: {
      textAlign: 'right',
    },
    paymentDetailsText: {
      color: 'textSecondary',
    },
  };
  