import React from 'react';
import HeaderTopBar from '../../../components/common/Header/HeaderTopBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Footer from '../../common/Footer/Footer';
import BannerSlider from '../../../components/common/Banner/Banner';
import homePageStyles from '../../../styles/PublicPage/home';
import DownloadTips from '../../common/Header/DownloadTips';
import { useMediaQuery, useTheme } from '@mui/material';

const HomePage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={homePageStyles.Box}>
      {isMobile && <DownloadTips />}
      <HeaderTopBar />
      <BannerSlider />

      <Box sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h5" component="h2" sx={{ mb: 6, fontWeight: 'bold' }}>
          Explore Our Foods
        </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', mb:1 }}>
                About Us               
              </Typography>
              <Typography variant="body1" sx={{ maxWidth: '100%', fontSize: '16px' }}>
                At Shake Salad, we specialise in healthy, convenient and affordable ready-to-eat food. Here's to healthy living made simple!              </Typography>
            </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default HomePage;