 
export const PATH_NAME = {
    ROOT: '/',
    ERROR_404: '/404',
    ERROR_403: '/403',
    MEMBER: '/member',
    LOGIN: '/login',
    TOPUP: '/member/topup',
    PROFILE: '/member/profile',
    EDIT_PROFILE: '/member/edit_profile',
    CARD_DETAILS: '/member/card_details/',
    ORDER_DETAILS: '/member/order_details/',
    INTRODUCTION: '/member/introduction',
    PAYMENT_SUCCESS: '/member/payment_success',

    // Authentication
    API_AUTH_LOGIN: '/api/auth/login',
    API_AUTH_SIGN_UP:'/api/auth/sign_up',
    API_AUTH_SEND_OTP:'/api/auth/send_otp',
    API_AUTH_SEND_OTP_FOR_SIGNUP:'/api/auth/send_otp_for_signup',
    API_AUTH_RESET_PASSWORD:'/api/auth/new_reset_password',
    API_AUTH_PROFILE: '/api/auth/profile',

    // Users
    API_QR_CODE: '/api/users/qr_code',
    API_GET_USER_VOUCHERS: '/api/users/get_my_coupons',
    API_MACHINE_LOGIN: '/api/users/machine_login',
    API_UPDATE_USER_INFO: '/api/users/update_user_info',
    API_USER_GET_REWARD_POINTS:'/api/users/get_reward_points',
    API_USER_GET_REWARD_POINTS_BALANCE:'/api/users/get_reward_point_balance',

    // Coupons
    API_VOUCHERS: '/api/coupons/',

    // StoreCredits
    API_STORE_CREDITS_BALANCE: '/api/store_credits/balance',
    
    // Payment
    API_PAYMENT_REQUEST_TOKEN: '/api/payment/request_payment_token',
    API_PAYMENT_PAYNOW: '/api/payment/paynow/',

    // PaymentMethod
    API_GET_MY_PAYMENT_METHODS: '/api/payment_method/get_my_payment_methods',
    API_DELETE_PAYMENT_METHOD:'/api/payment_method',

    // Orders
    API_GET_ORDER_ID:(order_id:string) => `/api/orders/${order_id}`,  
    API_GET_ORDER_BY_USER_ID:'/api/orders/get_my_orders',

    // Transactions
    API_TRANSACTION_START: '/api/transactions/start',
    API_GET_TRANSACTION_PAYMENT:(transaction_id:number) => `/api/transactions/get_transaction_payment/${transaction_id}`,
}