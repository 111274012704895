import axios from 'axios';
import axiosInstance from '../../api/axiosInstance';

export const generalFetcher = async (key: string) => {
  try {
    const response = await axiosInstance.get(key);
    return response.data.result;
  } catch (error) {
    handleError(error, 'fetching data');
    throw new Error('Failed to fetch data');
  }
};

export const generalItemFetcher = async (key: string) => {
  try {
    const response = await axiosInstance.get(key);
    if (response.data && response.data.result && Array.isArray(response.data.result.items)) {
      return response.data.result.items;
    } else {
      console.error('Invalid response structure for generalItemFetcher:', response.data);
      throw new Error('Invalid response structure');
    }
  } catch (error) {
    handleError(error, 'fetching items');
    throw new Error('Failed to fetch items');
  }
};

export const handleError = (error: unknown, context: string) => {
  if (axios.isAxiosError(error)) {
    console.error(`Error ${context}:`, error.message);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Request data:', error.request);
    }
  } else {
    console.error('Unexpected error:', error);
  }
};