import { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import { MenuItem } from '../models/IMenu';
import { SHAKE_HQ_APP, API_GET_MENU_BY_MACHINE_SLUG } from '../configs/config';

const Menu = (slug: string) => {
  const [data, setData] = useState<MenuItem[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`${SHAKE_HQ_APP}${API_GET_MENU_BY_MACHINE_SLUG}/${slug}`);
        const menuData = response.data.result;
  
        if (menuData && Array.isArray(menuData)) {
          setData(menuData);
          if (menuData.length > 0) {
            setSelectedCategory(menuData[0].main_tag_name);
          }
        } else {
          console.warn('No menu data found for this machine.');
          setData([]);
          setSelectedCategory('');
        }
      } catch (error) {
        console.error('Error fetching menu data:', error);
        setError('Failed to fetch menu data');
      }
    };
  
    fetchData();
  }, [slug]);  

  return { data, selectedCategory, setSelectedCategory, error };
};

export default Menu;