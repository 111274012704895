export const useResetPasswordOtpPageStyles = {
    section: {
        backgroundColor: 'background.default',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        mb: 2,
    },
    formContainer: {
        mt: 2,
    },
    textField: {
        mt: 2,
    },
    resetButton: {
        mt: 2,
        backgroundColor: '#136936',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#136936',
        },
    },
    resendContainer: {
        textAlign: 'center',
        mt: 2,
    },
    resendText: {
        color: '#aaaaaa',
        mt:2,
    },
};

export default useResetPasswordOtpPageStyles;
