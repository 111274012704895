import React from 'react';
import { Box, Card, CardContent, Typography, Grid, Divider } from '@mui/material';
import useSWR from 'swr';
import { PATH_NAME } from '../../../configs/pathName';
import { GetUserCoupon } from '../../../api/VoucherAPI';
import voucherPageStyles from '../../../styles/ProtectedPage/vouchers';
import HeaderStyles from '../../../styles/PublicPage/header';
import assets from '../../../assets';
import { generalFetcher } from '../../../helpers/Fetchers/fetchers';

const VouchersPage: React.FC = () => {
  const { data: profile } = useSWR(PATH_NAME.API_AUTH_PROFILE, generalFetcher);
  
  const { data: userCoupons, error, isLoading } = useSWR(
    profile ? PATH_NAME.API_GET_USER_VOUCHERS : null, 
    GetUserCoupon
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading vouchers</div>;

  return (
    <Box sx={voucherPageStyles.container}>
      <Typography variant="h6" sx={voucherPageStyles.title}>My Coupons</Typography>

      <Grid container spacing={2}>
        {userCoupons && userCoupons.length > 0 ? (
          userCoupons.map((coupon: any) => (
            <Grid item xs={12} sm={6} md={4} key={coupon.id}>
              <Card sx={voucherPageStyles.card}>
                <CardContent sx={voucherPageStyles.cardContent}>
                  <Box sx={voucherPageStyles.logoBox}>
                    <img src={assets.images.logo} alt="Logo" style={HeaderStyles.logo} />
                  </Box>

                  <Divider orientation="vertical" flexItem sx={voucherPageStyles.divider} />

                  <Box>
                    <Typography variant="h6" sx={voucherPageStyles.couponName}>{coupon.name}</Typography>
                    <Typography variant="body2" sx={voucherPageStyles.couponDetails}>
                      Code: {coupon.code}
                    </Typography>
                    <Typography variant="body1" sx={voucherPageStyles.couponDetails}>
                      {coupon.discount_type === 'percentage' ? (
                        <>
                          <Typography component="span" sx={voucherPageStyles.discountText}>
                            {coupon.discount_amount}%
                          </Typography> 
                          off
                        </>
                      ) : (
                        <>
                          <Typography component="span" sx={voucherPageStyles.discountText}>
                            ${coupon.discount_amount}
                          </Typography> 
                          coupon
                        </>
                      )}
                    </Typography>
                    <Typography variant="body2" sx={voucherPageStyles.minimumSpendingText}>
                      Any Purchase of ${coupon.minimum_spending} or more.
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No coupons available
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default VouchersPage;
