import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, IconButton, Avatar, Box, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import sizeConfigs from "../../../configs/sizeConfigs";
import colorConfigs from "../../../configs/colorConfigs";
import { PATH_NAME } from "../../../configs/pathName";
import { profileStyles } from "../../../styles/ProtectedPage/profile";
import { useNavigate } from "react-router-dom";

interface TopBarProps {
  onMenuClick: () => void;
}

const TopBar: React.FC<TopBarProps> = ({ onMenuClick }) => {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo) || '');
      setAvatarUrl(JSON.parse(storedUserInfo).avatarUrl || null);
    }
  }, []);

  const handleAvatarClick = () => {
    navigate(PATH_NAME.PROFILE);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${sizeConfigs.sidebar.width})` },
        ml: { sm: sizeConfigs.sidebar.width },
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onMenuClick}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1, justifyContent: "flex-end" }}>
          <IconButton sx={{ p: 0 }} onClick={handleAvatarClick}>
            <Avatar
              alt="User Avatar"
              src={avatarUrl || undefined}
              sx={{ width: 30, height: 30 }}
            >
              {!avatarUrl && <PersonIcon sx={{ fontSize: 20 }} />}
            </Avatar>
          </IconButton>
          <Typography sx={{ ...profileStyles, fontSize: "12px", ml: 1 }}>
            Hello, {userInfo?.firstname} {userInfo?.lastname}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
