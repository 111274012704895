import React, { useState } from 'react';
import { Box, Container, CssBaseline, Typography, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../redux/features/redux-hooks";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { login } from "../../../redux/features/Auth/AuthThunk";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HeaderTopBar from '../../../components/common/Header/HeaderTopBar';
import Footer from '../../common/Footer/Footer';
import { toast } from '../../../components/common/SnackBar/snackBar';
import loginPageStyles from '../../../styles/PublicPage/login';

const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState('');
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handlePhoneChange = (value: string, data: any) => {
        const phoneNumber = value.slice(data.dialCode.length); 
        setPhone(phoneNumber);
        setCountryCode(`+${data.dialCode}`);
    };

    const handleLogin = async () => {
        const phoneNumber = phone.replace(/[^0-9]/g, "");

        if (phone && password) {
            try {
                await dispatch(
                    login({
                        country_code: countryCode,
                        mobile: phoneNumber,
                        password,
                    })
                ).unwrap();
                toast.success("Login successfully!");
            } catch {
              // No action needed; errors handled at API level
            }
        } else {
          toast.error("Please enter Phone Number & Password.");
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
        <Box sx={loginPageStyles.container}>
            <HeaderTopBar />
            <Container maxWidth="xs" sx={loginPageStyles.contentContainer}>
                <CssBaseline />
                    <Typography variant="h4" component="h1" gutterBottom align="left" sx={loginPageStyles.headerText}>
                        Hi! <br />
                    </Typography>
                    <Typography variant="h4" component="h1" gutterBottom align="left" sx={loginPageStyles.subHeaderText}>
                        Welcome
                    </Typography>
                    <Typography variant="body1" gutterBottom align="left" sx={loginPageStyles.descriptionText}>
                        Please enter your details
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Box sx={loginPageStyles.phoneInputContainer}>
                            <PhoneInput
                                country={'sg'}
                                value={countryCode + phone}
                                onChange={handlePhoneChange}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true,
                                }}
                                containerStyle={{ width: '100%' }}
                                inputStyle={{ width: '100%' }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={loginPageStyles.textField}
                            />
                            <Box sx={loginPageStyles.formActions}>
                                <Typography variant="body2">
                                    <Link to={'/forget-password'} style={loginPageStyles.forgotPasswordLink}>
                                        Forgot password?
                                    </Link>
                                </Typography>
                            </Box>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={loginPageStyles.loginButton}
                                onClick={handleLogin}
                            >
                                Login
                            </Button>
                            <Typography variant="body2" align="center">
                                Don’t have an account yet?{' '}
                                <Link to={'/register'} style={loginPageStyles.signupLink}>
                                    Sign up
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Container>
                <Footer/>
            </Box>
        </>
    );
};

export default Login;